
import { Component } from 'nuxt-property-decorator'
import { User } from '@simple-payments/shared-packages/types/User'
import Mail from '@simple-payments/shared-packages/classes/Mail'
@Component({
  fetchOnServer: false
})
export default class extends Mail {
  user!: User
  subscribeWithClear () {
    this.subscribe().then(() => {
      this.$emit('formclear')
    })
  }

  mounted () {
    this.$nuxt.$eventBus.$on('form-with-email', this.subscribeWithClear)
  }

  beforeDestroy () {
    this.$nuxt.$eventBus.$off('form-with-email', this.subscribeWithClear)
  }

  async fetch () {
    await this.$axios
      .get(process.env.API_ENDPOINT + 'user')
      .then((response: any) => {
        this.user = response.data
      })
    if (this.user.email) {
      this.formData.email = this.user.email
    }
  }
}
